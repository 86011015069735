<template>
  <section id="dashboard-ecommerce">
    <b-row
      v-if="trueLineChart"
      class="match-height"
    >
      <b-col
        sm="12"
        md="12"
        lg="5"
        xl="6"
      >
        <h2 style="padding-left: 20px;">
          {{ currentCountAssocieteStatics }}
        </h2>
        <h4 style="padding-left: 20px;">
          Associados
        </h4>
        <vue-apex-charts
          type="line"
          height="400"
          :options="lineChartSimple.chartOptions"
          :series="lineChartSimple.series"
        />
        {{ lineChartSimple.series }}
        <br>
        {{ apexChatData.lineChartSimple.series }}
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="5"
        xl="6"
      >
        <h2 style="padding-left: 20px;">
          {{ currentCountAssocieteStatics - oldCountAssocieteStatics }}
        </h2>
        <h4 style="padding-left: 20px;">
          Diferença {{ currentYear -1 }} - {{ currentYear }}
        </h4>
        <vue-apex-charts
          type="line"
          height="400"
          :options="lineChartSimple.chartOptions"
          :series="apexChatData.lineChartSimple.series"
        />
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div class="row">
          <div
            v-for="item in Years"
            :key="item.id"
            class="col-1"
            style="padding-left: 30px;"
          >
            <p>{{ item }}</p>
            <p>R$: </p>
          </div>
        </div>
        <vue-apex-charts
          type="line"
          height="400"
          :options="lineChartSimple.chartOptions"
          :series="apexChatData.lineChartSimple.series"
        />
      </b-col>
      <!-- Browser States Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/no-unresolved
import { $themeColors } from '@themeConfig'

// import { getUserData } from '@/auth/utils'
import StatisticCardWithLineChart from '../../../../components/dashboard/StatisticCardWithLineChart.vue'
import CardAnalyticSalesRadarChart from '../../../../components/dashboard/CardAnalyticSalesRadarChart.vue'
import UserService from './usersService'

import { mapActions } from 'vuex'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'

const $trackBgColor = '#EBEBEB'
export default {
  components: {
    BRow,
    BCol,
    VueApexCharts,

  },
  data() {
    return {
      StaticsValue: '',
      StaticsValueListSerieCount: [],
      Years: [],
      currentYear: new Date().getFullYear(),
      currentYearNext: new Date().getFullYear() + 1,
      oldCountAssocieteStatics: '',
      currentCountAssocieteStatics: '',
      trueLineChart: false,
      lineChartSimple: {
        series: [
          {
            data: this.StaticsValueListSerieCount
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
              }%</span></div>`
            },
          },
          xaxis: {
            categories: ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      data: {
        statisticsProfit: {
          series: [
            {
              data: [1000, 580, 950, 700, 1100, 300],
            },
          ],
        },
        activeUsers: {
          series: [
            {
              name: 'Active Users',
              data: [750, 1000, 900, 1250, 1000, 1200, 1100],
            },
          ],
        },
        staticAcess: {
          access: 0,
          series: [
            {
              name: 'Quarta',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'Sabado',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        statisticsItemsTime: [
          {
            coloricon: '#5193d1', icon: 'ClockIcon', color: 'light-info', title: 'Quantidade de horas de aulas assistidas', subtitle: ''
          },
          {
            coloricon: '#7C70F1', icon: 'InfoIcon', color: 'light-primary', title: 'Quantidade de atividades', subtitle: ''
          },
        ],
        statisticsItems: [
          {
            coloricon: '#7C70F1', icon: 'ActivityIcon', color: 'light-primary', title: ' ', subtitle: 'Numero de inscritos totais'
          },
          {
            coloricon: '#5193d1', icon: 'BriefcaseIcon', color: 'light-info', title: ' ', subtitle: 'Residentes'
          },
          {
            coloricon: '#29C770', icon: 'HeartIcon', color: 'light-success', title: ' ', subtitle: 'Médicos'
          },
          {
            coloricon: '#7C70F1', icon: 'BookIcon', color: 'light-primary', title: ' ', subtitle: 'Estudantes acadêmico'
          },
          {
            coloricon: '#5193d1', icon: 'ShoppingBagIcon', color: 'light-info', title: ' ', subtitle: 'Promocional'
          },
          {
            coloricon: '#29C770', icon: 'ToolIcon', color: 'light-success', title: ' ', subtitle: 'Outros profissionais'
          },
          {
            coloricon: '#7C70F1', icon: 'VideoIcon', color: 'light-primary', title: ' ', subtitle: 'Palestrantes'
          },
          {
            coloricon: '#5193d1', icon: 'Edit2Icon', color: 'light-info', title: ' ', subtitle: 'Fellow'
          },
          {
            coloricon: '#29C770', icon: 'ArchiveIcon', color: 'light-success', title: ' ', subtitle: 'Administração SBAO'
          },
          {
            coloricon: '#7C70F1', icon: 'UserIcon', color: 'light-primary', title: ' ', subtitle: 'Acima dos 70'
          },
          {
            coloricon: '#5193d1', icon: 'UserPlusIcon', color: 'light-info', title: ' ', subtitle: 'Patrocinadores'
          },
          {
            coloricon: '#EE7778', icon: 'AlertTriangleIcon', color: 'light-danger', title: ' ', subtitle: 'Cancelados'

          },
        ],
        statisticsOrder: {
          series: [
            {
              name: 0,
              total: 0,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
          ],
        },
        earningsChart: {
          series: [53, 16, 31],
        },
        revenue: {
          years: ['2020', '2019', '2018'],
          price: '25,852',
          budget: '56,800',
          revenueReport: {
            series: [
              {
                name: 'Earning',
                data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
              },
              {
                name: 'Expense',
                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
              },
              {
                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
              },
            ],
          },
        },
      },
      excel_init: false,
      items: [],
      tableColumnsCongress: [
        {
          key: 'id',
          field: 'id',
          label: 'Código',
          sortable: true
        },
        {
          key: 'name',
          field: 'name',
          label: 'Nome',
          sortable: true
        },
        {
          key: 'email',
          field: 'email',
          label: 'Email',
          sortable: false,
        },
        {
          key: 'role',
          field: 'role',
          label: 'Papel',
          sortable: true,
          formatter(v, k, item) {
            let role = ''
            switch (v.toString()) {
              case 'MASTER':
                role = 'Mestre'
                break
              case 'ADMIN':
                role = 'Administrador'
                break
              case 'PAID':
                role = 'Pagador(a)'
                break
              case 'COMMON':
                role = 'Comum'
                break
              case 'SECRETARY':
                role = 'Secretário(a)'
                break
              case 'SECRETARY-GENERAL':
                role = 'Secretário(a) Geral'
                break
              case 'TREASURER':
                role = 'Tesoureiro'
                break
              case 'PRESIDENT':
                role = 'Presidente'
                break
              case 'VICE-PRESIDENT':
                role = 'Vice-Presidente'
                break
              case 'SPEAKER':
                role = 'Palestrante'
                break
              case 'SPONSOR':
                role = 'Patrocinador'
                break
              case 'SCIENTIFIC-DIRECTOR':
                role = 'Diretor Científico'
                break
              case 'COURSE-DIRECTOR':
                role = 'Diretor do Curso'
                break
              case 'COMMUNICATION-DIRECTOR':
                role = 'Diretor de Comunicação'
                break
              case 'CONGRESS-ADMINISTRATOR':
                role = 'Administrador do Congresso'
                break
              case 'PROFESSOR':
                role = 'Professor'
                break
              case 'ADVISOR':
                role = 'Orientador'
                break
              case 'SPONSOR-COMMUNICATION':
                role = 'Patrocinador de Comunicação'
                break
              case 'CONGRESS-SPONSOR':
                role = 'Patrocinador do Congresso'
                break
              case 'JURIDICAL':
                role = 'Jurídico'
                break
              case 'STATE-PRESIDENT':
                role = 'Presidente de Estado'
                break
              case 'SNNO-ASSOCIATE':
                role = 'Associado SNNO'
                break
              case 'ART-MANAGER':
                role = 'Gerente de Arte'
                break
              default:
                role = 'Desconhecido'
            }
            return role
          }
        },
        {
          key: 'associate',
          field: 'associate',
          label: 'Associado',
          sortable: false,
        },
        {
          key: 'actions',
          field: 'actions',
          label: 'Ações',
          sortable: false,
        }
      ],
      rows: 0,
      perPage: 10,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prevPage: this.currentPage - 1,
      sortBy: 'id',
      sortDesc: true,
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      searchValue: '',
      isBusy: false,
      isRemoving: false,
      rowData: {},
      columns: [
        {
          label: 'Product',
          field: 'product',
        },
        {
          label: 'Price',
          field: 'price',
          dataFormat: this.priceFormat
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
      ],
    }
  },
  beforeMount() {
    this.listUsers()
    this.listStatics()
  },
  methods: {
    ...mapActions('congressAdministrator', [
      'ActionGetStatistics'
    ]),
    priceFormat(value) {
      return `$ ${value}`
    },
    onSaved(saved) {
      this.listUsers()
    },
    listUsers() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      this.items = []
      this.isBusy = true
      UserService().fetchUsers(params).then(data => {
        this.items = data.response.users
        this.rows = data.response.users.length
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
      this.excel_init = true
    },
    listStatics() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      this.StaticsValue = []
      this.isBusy = true
      UserService().statics(params).then(data => {
        this.StaticsValue = data.response.associateListYear
        const ano = new Date().getFullYear()
        let count = 2010
        this.StaticsValue.forEach((year, index) => {
          this.Years.push(count)
          count += 1
          for (let i = 2010; i < this.currentYearNext; i += 1) {
            if (year[i] !== undefined) {
              this.StaticsValueListSerieCount.push(year[i])
            }
          }
          if (year[ano] !== undefined) {
            this.currentCountAssocieteStatics = year[new Date().getFullYear()]
          }
          if (year[ano - 1] !== undefined) {
            this.oldCountAssocieteStatics = year[ano - 1]
          }
        })
        this.lineChartSimple.series[0].data = this.StaticsValueListSerieCount
        this.rows = data.response.associateListYear.length
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
      this.trueLineChart = true
    },
    password(data) {
      this.rowData.id = data.id
      this.isPassUserSidebarActive = true
    },
    detail(data) {
      this.rowData.id = data.id
      this.rowData.name = data.name
      this.rowData.cpf = data.cpf
      this.rowData.role = data.role
      this.rowData.email = data.email
      this.rowData.phone = data.phone
      this.rowData.profession = data.profession
      this.rowData.birthDate = this.$moment(data.birthDate).format()
      this.rowData.crm = data.crm
      this.rowData.crm_uf = data.crm_uf
      this.rowData.active = data.active
      this.isDetailUserSidebarActive = true
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.name = data.name
      this.rowData.cpf = data.cpf
      this.rowData.role = data.role
      this.rowData.email = data.email
      this.rowData.phone = data.phone
      this.rowData.profession = data.profession
      this.rowData.birthDate = this.$moment(data.birthDate).format()
      this.rowData.crm = data.crm
      this.rowData.crm_uf = data.crm_uf
      this.rowData.active = data.active
      this.isEditUserSidebarActive = true
    },
    remove(data) {
      this.$swal({
        title: 'Deletar usuário?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => UserService().deleteUsers(data.id).then(response => {
          if (response.status === 200) {
            const new_items = this.items.filter((v) => v.id !== data.id)
            this.items = new_items
          }
        }).catch(err => {
          this.$swal.fire(
            'Deletado!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Congresso deletado com sucesso.',
            'success'
          )
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
